.listContainer {
  counter-reset: listCounter;
  // display: grid;
  // grid-template-rows: auto;
}

.listItem {
  margin-left: 40px;
  display: flex;
  flex-wrap: nowrap;
//   justify-content: space-between;
  white-space: nowrap;
}
.listItem.numbered {
  &:before {
    counter-increment: listCounter;
    content: counter(listCounter, lower-alpha) ") ";
  }
}
.listItem.dotted {
  &:before {
    margin-right: 4px;
    content: "●";
  }
}
.note{
    font-size: 0.75em;
    margin-left: 40px;
    padding-top: 10px;
}
.pad{
    padding-top: 10px;
    padding-bottom: 10px;
}
@import '../../theme/color-table.scss';
.page-parent{
    padding: 20px;
}
p{
    padding: 10px 0 10px 0;
}


@media print{
    .page-parent{
        padding: 0;


    }
    p{
        padding: 3px;
    }
    
}
section.pagina{
    input{
        margin: 0;
        padding: 0;
        font-size: 10pt;
        border: none;
        border-bottom: 1px dotted darkgray;
        font-weight: 700;
        // padding-left: 5px;
        // padding-right: 5px;
        margin:0 0 0 5px  ;
    }
}
.centrat{
    text-align: center;
}
.cnp{
    letter-spacing: 5px;
}


@media print {
    section.pagina {
        input{
            border-bottom: 1px dotted darkgray;
        }
    }
}
.main-container {
  display: grid;
  grid-template-columns: 0.75fr 217mm 0.75fr;
  margin: auto;
  padding-top:40px ;
}
html {
  scroll-behavior: smooth;
}


@media print {

  //set the page to A4
  @page {
    margin: 0;
    size: A4 portrait;
  }

  //make it look like a block not a grid so it can break the page
  .main-container {
    display: block;
    margin-top: 20px;
    margin-left: 55px;
    margin-bottom: 25px;
    margin-right: 20px;
    padding: 0;
  }

  .panel {
    visibility: hidden;
    display: none;
  }
}
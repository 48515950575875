$light-grey: #cfccd6ff;
$dark-grey:#2a2d34ff;
$beneficiar-border: #d112c1;
$imobil-border: #6279b8ff;
$proiectant-border:#e6480fff;

$arhitextura-dark:#24292c;
$arhitextura-medium:#3f3f3d;
$arhitextura-accent:#f39f22;
$arhitextura-alt-medium:#4e5a66;
$arhitextura-light:#eeefe9;





@mixin rounded-corners {
    // border: 1px solid $light-grey;
    border-radius: 4px;
    box-shadow: 2px 1px 6px -2px rgba($dark-grey, 0.75);
    

}

@mixin reset-borders {
    border: none;
    border-radius: 0;
    box-shadow: none;

}
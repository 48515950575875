@import '../../theme/color-table.scss';
label{
    display: block;
    cursor: pointer;
    user-select: none;
    input[type=checkbox]{
        opacity: 0.0;   
        position: absolute;
        width: 0;
        height: 0;
}
}
.checkbox-arhUI{
    width: 10px;
    height: 10px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    border-radius: 3px;
    border: 2px solid $dark-grey;
    margin-right: 2px;
    
    .circle {
        position: absolute;
        width: 30px;
        height: 30px;
        left: 50%;
        top: 50%;
        border-radius: 100%;
        transform: translate(-50%, -50%) scale(0);
        background-color: rgba($light-grey, 0.5);
        z-index: -5;
        transition: transform 0.1s ease-in;
    &.focused{
        background-color: rgba($light-grey, 0.5);
        transform: translate(-50%, -50%) scale(1);
        transition: transform 0.2s ease-in-out;


    }
}   
    

    svg{
        vertical-align: top ;
        transform: scale(0);
        transition: transform 0.2s ease-in-out;
        fill: $dark-grey;
        background-color: none;
        &.checked{
            background-color: none;
            transform: scale(2.0);
            transition: transform 0.2s ease-in-out;
        }

    }
    
}
@import '../../theme/color-table.scss';

.header_bar{
    display: flex;
    position: fixed;
    // grid-area: header;
    width: 100%;
    background-color:white;
    height: 50px;
    box-shadow: 1px 1px 5px -2px;
    z-index: 5;

}

.logo {
    padding-top: 20px;
    height: 90%;
    padding: 0;
    padding-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    cursor: pointer;
}

@media print{
    .header_bar{
        display: none;
    }
}